import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { getExperimentVariant } from 'components/api/experiments';

import { ReturnExperienceData, useReturnExperience } from './returnBanner';

export type ReturnExperienceContextProps = {
  returnExperienceData: ReturnExperienceData | null;
  returnExperienceExperimentVariant: string | undefined;
  showReturnExperience: boolean | undefined;
};

export const ReturnExperienceContext = createContext<ReturnExperienceContextProps>({
  returnExperienceData: null,
  returnExperienceExperimentVariant: undefined,
  showReturnExperience: false,
});

interface ReturnExperienceContextProviderProps {
  showReturnExperienceBanner: boolean | undefined;
  children: ReactNode;
}

export const ReturnExperienceContextProvider = ({
  children,
  showReturnExperienceBanner,
}: ReturnExperienceContextProviderProps) => {
  const [returnExperienceExperimentVariant, setReturnExperienceExperimentVariant] = useState<
    string | undefined
  >(undefined);
  const { returnExperienceData } = useReturnExperience();
  const showReturnExperience = showReturnExperienceBanner;

  useEffect(() => {
    // only fetch variant/trigger impression if return user
    if (returnExperienceData?.experienceType !== null) {
      getExperimentVariant({
        key: 'growth-25-03-return-experience-placement',
        autoImpression: true,
      }).then((variant) => {
        setReturnExperienceExperimentVariant(variant);
      });
    }
  }, [returnExperienceData]);

  return (
    <ReturnExperienceContext.Provider
      value={{ returnExperienceData, returnExperienceExperimentVariant, showReturnExperience }}
    >
      {children}
    </ReturnExperienceContext.Provider>
  );
};

export const useReturnExperienceContext = () => useContext(ReturnExperienceContext);
