/* storybook-check-ignore */
import { useEffect, useState } from 'react';

import { Box, Button, Dialog, Link, Text, useMedia } from '@opendoor/bricks-next';
import { centsToCurrencyString } from '@opendoor/bricks/helpers/currencyHelpers';

import { useReturnExperienceContext } from 'helpers/ReturnExperienceContext';

import { ExperienceType } from '../../helpers/returnBanner';
import { CONSUMER_FE_URL, COSMOS_URL } from '../globals';

type CTAButtonProps = {
  experience: ExperienceType;
  loading?: boolean;
  onClick?: () => void;
  url?: string;
  actionText: string;
};

const CTAButton = (props: CTAButtonProps): JSX.Element => {
  return (
    <Button
      maxWidth="auto"
      variant="primary"
      size="small"
      analyticsName={`cosmos-return-banner-${ExperienceType[props.experience]}`}
      trackImpression
      aria-label="return-banner-cta"
      loading={props.loading}
      href={props.url}
      label={props.actionText}
    ></Button>
  );
};

const getActiveOfferExpiryText = (daysToExpire = 10) => {
  let expiryText = 'is waiting';
  if (daysToExpire < 1) {
    expiryText = 'expires today';
  } else if (daysToExpire < 2) {
    expiryText = 'expires in 1 day';
  } else if (daysToExpire < 3) {
    expiryText = 'expires in 2 days';
  } else if (daysToExpire === 3) {
    expiryText = 'expires in 3 days';
  }
  return expiryText;
};

type HeadlineProps = {
  experience: ExperienceType;
  currentOfferPriceCents?: number;
  previousOfferPriceCents?: number;
  daysToExpire?: number;
  smallerThanMD: boolean;
  isModal: boolean;
};

const Headline = (props: HeadlineProps): JSX.Element | null => {
  const { largerThanSM } = useMedia();
  const currencyTypography =
    props.isModal && largerThanSM ? '$headerLarge' : props.isModal ? '$headerSmall' : '$labelLarge';
  switch (props.experience) {
    case ExperienceType.offer_expired:
      return <>Get your updated offer{props.smallerThanMD ? '' : ' today'}</>;
    case ExperienceType.incomplete_flow:
      return <>Finish your offer{props.smallerThanMD ? '' : ' today'}</>;
    case ExperienceType.offer_increased:
      return !isNaN(props.currentOfferPriceCents ?? NaN) &&
        !isNaN(props.previousOfferPriceCents ?? NaN) ? (
        <>
          Your offer increased by{' '}
          <Text tag="span" color="$backgroundAccentBrand" typography={currencyTypography}>
            {centsToCurrencyString(
              (props.currentOfferPriceCents ?? 0) - (props.previousOfferPriceCents ?? 0),
            )}
          </Text>
        </>
      ) : null;
    case ExperienceType.offer_expiring:
    case ExperienceType.offer_decreased:
    case ExperienceType.active_offer:
      return !isNaN(props.currentOfferPriceCents ?? NaN) ? (
        <>
          Your offer of{' '}
          <Text tag="span" color="$backgroundAccentBrand" typography={currencyTypography}>
            {centsToCurrencyString(props.currentOfferPriceCents ?? 0)}
          </Text>{' '}
          {getActiveOfferExpiryText(props.daysToExpire)}
        </>
      ) : null;
    case ExperienceType.home_insights:
      return <>Get back to your{props.smallerThanMD ? <br /> : ' '}home insights</>;
    default:
      return null;
  }
};

type ExperienceCTAButtonProps = {
  experience: ExperienceType;
  sellerInputUUID?: string;
  customerUUID?: string;
  addressToken?: string;
  smallerThanMD?: boolean;
};

const ExperienceCTAButton = (props: ExperienceCTAButtonProps): JSX.Element | null => {
  let offerDashUrl = COSMOS_URL;
  if (props.addressToken) {
    // Return to sell tab in hub for returning customers
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/sell/${props.addressToken}`;
  } else if (props.sellerInputUUID) {
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/${props.sellerInputUUID}/overview`;
  }

  switch (props.experience) {
    case ExperienceType.offer_expired: {
      const baseUrl = offerDashUrl;
      const params = new URLSearchParams({
        refresh_offer: 'true',
        refresh_source: 'homepage_experience',
      }).toString();
      const refreshUrl = `${baseUrl}?${params}`;
      return (
        <CTAButton
          actionText={`Refresh${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={refreshUrl}
          experience={props.experience}
        />
      );
    }
    case ExperienceType.incomplete_flow:
      // eslint-disable-next-line no-case-declarations
      const sellerFlowUrl = `${CONSUMER_FE_URL}/seller-lead/${props.sellerInputUUID}`;
      return (
        <CTAButton
          actionText={`Resume${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={sellerFlowUrl}
          experience={props.experience}
        />
      );
    case ExperienceType.offer_increased:
    case ExperienceType.offer_expiring:
    case ExperienceType.offer_decreased:
    case ExperienceType.active_offer:
      return (
        <CTAButton
          actionText={`View${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={offerDashUrl}
          experience={props.experience}
        />
      );
    case ExperienceType.home_insights:
      // eslint-disable-next-line no-case-declarations
      const homeInsightsUrl = `${CONSUMER_FE_URL}/home-insights`;
      return (
        <CTAButton
          actionText={`View insights`}
          url={homeInsightsUrl}
          experience={props.experience}
        />
      );
    default:
      return null;
  }
};

export const ReturnExperienceBanner = () => {
  const { returnExperienceData } = useReturnExperienceContext();

  const { smallerThanMD } = useMedia();

  if (!returnExperienceData || returnExperienceData?.experienceType === null) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      zIndex={5}
      top={73}
      px={15}
      py={10}
      width={350}
      $largerThanSM={{
        width: 646,
        py: 15,
        pl: 32,
        top: 80,
      }}
      backgroundColor="$backgroundSecondary"
      borderColor="$borderStateInactiveTertiary"
      borderRadius={24}
      justifyContent="space-between"
      alignItems="center"
      className="hp-reskin-syw-return-experience-banner-wrapper"
    >
      <Box mt={10}>
        <Text tag="span" color="$contentPrimary" typography="$labelLarge">
          <Headline
            experience={returnExperienceData.experienceType}
            currentOfferPriceCents={returnExperienceData.currentOfferHeadlinePriceCents}
            previousOfferPriceCents={returnExperienceData.previousOfferHeadlinePriceCents}
            daysToExpire={returnExperienceData.offerExpiresInDays}
            smallerThanMD={smallerThanMD}
            isModal={false}
          />
        </Text>
        <Text mt={4} tag="span" color="$contentSecondary" typography="$bodyMedium">
          {returnExperienceData.address}
        </Text>
      </Box>
      <ExperienceCTAButton
        experience={returnExperienceData.experienceType}
        sellerInputUUID={returnExperienceData.sellerInputUUID}
        customerUUID={returnExperienceData.customerUUID}
        addressToken={returnExperienceData.addressToken}
        smallerThanMD={smallerThanMD}
      />
    </Box>
  );
};

export const ReturnExperienceModal = () => {
  const { returnExperienceData } = useReturnExperienceContext();
  const [isOpen, setIsOpen] = useState(false);
  const { smallerThanSM } = useMedia();

  useEffect(() => {
    const modalDismissed = sessionStorage.getItem('returnExperienceModalDismissed') === 'true';
    const hasValidExperience = returnExperienceData && returnExperienceData.experienceType !== null;

    let timer: NodeJS.Timeout;
    if (hasValidExperience && !modalDismissed && !isOpen) {
      timer = setTimeout(() => {
        setIsOpen(true);
      }, 400);
    }

    return () => clearTimeout(timer);
  }, [returnExperienceData, isOpen]);

  if (!returnExperienceData || returnExperienceData?.experienceType === null) {
    return null;
  }

  const handleClose = () => {
    setIsOpen(!isOpen);
    sessionStorage.setItem('returnExperienceModalDismissed', 'true');
  };

  return (
    <Dialog
      analyticsName="cosmos-return-banner-modal"
      dismissable
      overlayColor="dark"
      setIsOpen={handleClose}
      trackImpression
      isOpen={isOpen}
    >
      <Box m={'$16x'} $largerThanSM={{ m: '$24x' }} $largerThanMD={{ mt: 64 }}>
        <Text
          tag="span"
          color="$contentPrimary"
          typography="$subheaderLarge"
          $largerThanSM={{ typography: '$headerLarge', maxWidth: 458 }}
        >
          <Headline
            experience={returnExperienceData.experienceType}
            currentOfferPriceCents={returnExperienceData.currentOfferHeadlinePriceCents}
            previousOfferPriceCents={returnExperienceData.previousOfferHeadlinePriceCents}
            daysToExpire={returnExperienceData.offerExpiresInDays}
            smallerThanMD={smallerThanSM}
            isModal={true}
          />
        </Text>
        <Box
          backgroundColor="$backgroundSecondary"
          borderRadius={16}
          p={16}
          $largerThanSM={{ p: 24, mt: 32, borderRadius: 24 }}
          mt={24}
        >
          <Text
            tag="span"
            color="$contentPrimary"
            typography="$bodyLarge"
            $largerThanSM={{ typography: '$bodyXlarge' }}
          >
            {returnExperienceData.addressFull}
          </Text>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          gap={2}
          mt={16}
          mb={24}
          $largerThanSM={{ mt: '$12x', mb: '$16dot5x' }}
        >
          <Text tag="span" color="$contentSecondary" typography="$bodyMedium">
            Need to start over?{' '}
          </Text>
          <Link
            variant="primary"
            typography="$labelMedium"
            analyticsName="cosmos-return-banner-modal-enter-new-address"
            href={`${COSMOS_URL}/address-entry`}
          >
            Enter a new address
          </Link>
        </Box>
        <ExperienceCTAButton
          experience={returnExperienceData.experienceType}
          sellerInputUUID={returnExperienceData.sellerInputUUID}
          customerUUID={returnExperienceData.customerUUID}
          addressToken={returnExperienceData.addressToken}
          smallerThanMD={false}
        />
      </Box>
    </Dialog>
  );
};
